import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from "../services/authentication.service";

@Injectable({
	providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
	constructor(
		private _authenticationService: AuthenticationService,
		private _router: Router
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree {
		const currentUser = this._authenticationService.currentUserValue;
		return currentUser ? this._router.createUrlTree(['/general/users/list']) : true;
	}

}
