// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {IEnvironment} from "./core/interfaces/IEnvironment";

export const environment: IEnvironment = {
	production: false,
	hmr: false,
	apiUrl: 'https://adm-alp-sb1.ivlk.io/admin_panel',
	socketUrl: 'wss://ws-alp-sb1.investlink.io/ws',
	clientTradeUrl: 'https://trc.ivlk.io/client_trade/v1',
	managers_ids: [292, 4, 2, 88, 661, 318],
	super_managers_ids: [399, 612, 318, 165],
	apiAlpacaUrl: 'https://broker-api.sandbox.alpaca.markets',
	alpacaToken: 'Q0s3NlFMWEZRUlFONEMxSzAxMTc6Qm1LdTZpT0t6V0lnQmhaaDhBYTRnVHlGdXhxYTFtMjZZZGtTTFA1eA==',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
