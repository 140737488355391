import { CoreMenu } from '@core/types'
import {IAuthResponse} from "../modules/auth/core/interfaces/IAuthResponse";

const currentUser: IAuthResponse | undefined = JSON.parse(localStorage.getItem('currentUser'))

export const menu: CoreMenu[] = [
	{
		id: 'general',
		title: 'Общее',
		type: 'section',
		children: [
			{
				id: 'users',
				title: 'Пользователи',
				type: 'item',
				icon: 'users',
				url: '/general/users'
			},
			{
				id: 'bids-kyc',
				title: 'Заявки KYC (old)',
				type: 'item',
				icon: 'alert-triangle',
				url: '/general/bids-kyc',
				hidden: !currentUser?.is_super_manager && !currentUser?.is_manager,
			},
			{
				id: 'collections',
				title: 'Список подборок',
				type: 'item',
				icon: 'list',
				url: '/general/collections',
				hidden: !currentUser?.is_super_manager
			},
			{
				id: 'transactions',
				title: 'Транзакции',
				type: 'item',
				icon: 'repeat',
				url: '/general/transactions',
				hidden: !currentUser?.is_super_manager
			},
			{
				id: 'commissions',
				title: 'Комиссии',
				type: 'item',
				icon: 'percent',
				url: '/general/commissions',
				hidden: !currentUser?.is_super_manager
			},
			{
				id: 'client-trade',
				title: 'Торговля за клиента',
				type: 'collapsible',
				icon: 'shuffle',
				hidden: !currentUser?.is_super_manager,
				children: [
					{
						id: 'client-trade-trading',
						title: 'Торговля',
						type: 'item',
						url: '/general/client-trade/trading',
						disabled: true,
					},
					{
						id: 'client-trade-groups',
						title: 'Группы',
						type: 'item',
						url: '/general/client-trade/groups',
						disabled: true,
					},
				]
			},
		]
	},
	{
		id: 'moderation-kyc',
		title: 'Модерация KYC',
		type: 'section',
		hidden: !currentUser?.is_super_manager && !currentUser?.is_manager,
		children: [
			{
				id: 'bids',
				title: 'Заявки',
				type: 'item',
				icon: 'inbox',
				url: '/moderation-kyc/bids',
				hidden: !currentUser?.is_super_manager && !currentUser?.is_manager
			},
			{
				id: 'error',
				title: 'Ошибки',
				type: 'item',
				icon: 'alert-triangle',
				url: '/moderation-kyc/errors',
				hidden: !currentUser?.is_super_manager && !currentUser?.is_manager
			}
		]
	},
	{
		id: 'tariffs',
		title: 'Тарифы',
		type: 'section',
		hidden: !currentUser?.is_super_manager,
		children: [
			{
				id: 'tariff-list',
				title: 'Список тарифов',
				type: 'item',
				icon: 'credit-card',
				url: '/tariffs/tariff-list',
				hidden: !currentUser?.is_super_manager
			},
			{
				id: 'tariff-group',
				title: 'Группы тарифов',
				type: 'item',
				icon: 'grid',
				url: '/tariffs/tariff-group',
				hidden: !currentUser?.is_super_manager
			}
		]
	},
	{
		id: 'settings',
		title: 'Настройки',
		type: 'section',
		hidden: !currentUser?.is_super_manager,
		children: [
			{
				id: 'systemic',
				title: 'Системные',
				type: 'collapsible',
				icon: 'settings',
				hidden: !currentUser?.is_super_manager,
				children: [
					{
						id: 'mobile-versions',
						title: 'Версии приложений',
						type: 'item',
						url: '/settings/systemic/mobile-versions',
						hidden: !currentUser?.is_super_manager
					},
					{
						id: 'duty-manager',
						title: 'Дежурный менеджер',
						type: "item",
						url: '/settings/systemic/duty-manager',
						hidden: !currentUser?.is_super_manager
					}
				]
			}
		]
	},
	{
		id: 'other',
		title: 'Другое',
		type: 'section',
		hidden: !currentUser?.is_super_manager,
		children: [
			{
				id: 'analytics',
				title: 'Аналитика',
				type: 'item',
				icon: 'bar-chart-2',
				url: '/other/analytics',
				hidden: !currentUser?.is_super_manager
			},
			{
				id: 'reports',
				title: 'Отчеты',
				type: 'item',
				icon: 'book',
				url: '/other/reports',
				hidden: !currentUser?.is_super_manager
			}
		]
	}
]
