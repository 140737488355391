import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from "../services/authentication.service";
import { ToastrService } from "ngx-toastr";


@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private _router: Router,
		private _authenticationService: AuthenticationService,
		private _toastrService: ToastrService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this._authenticationService.currentUserValue;
		if (!currentUser) {
			this._router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
			this._toastrService.error('У вас нет доступа к этой странице', 'Ошибка', { toastClass: 'toast ngx-toastr', closeButton: true, positionClass: '' })
			return false;
		}
		return true;
	}

}
