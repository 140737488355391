import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import {IAuthData} from "../interfaces/IAuthData";
import {IAuthResponse} from "../interfaces/IAuthResponse";

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	private _currentUserSubject: BehaviorSubject<any>;

	public currentUser$: Observable<any>;

	constructor(
		private _http: HttpClient
	) {
		this._initUser()
	}

	get currentUserValue(): any {
		return this._currentUserSubject.value;
	}

	private _initUser() {
		this._currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser$ = this._currentUserSubject.asObservable();
	}

	public login(data: IAuthData): Observable<IAuthResponse> {
		return this._http
			.post<IAuthResponse>(`${ environment.apiUrl }/auth_db/login/`, data)
			.pipe(
				tap((user: IAuthResponse) => {
					if (user && user?.tokens) {
						localStorage.setItem('currentUser', JSON.stringify(user));
						this._currentUserSubject.next(user);
					}
				})
			);
	}

	public logout() {
		localStorage.removeItem('currentUser');
		localStorage.removeItem('pageSizes');
		this._currentUserSubject.next(null);
	}

}
