import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { appRouting } from "./app.routing";
import { JwtInterceptor } from "./modules/auth/core/interceptor/jwt.interceptor";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

const AUTH_HTTP_INTERCEPTOR_PROVIDER = {
	provide: HTTP_INTERCEPTORS,
	useClass: JwtInterceptor,
	multi: true
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot(appRouting, {
			scrollPositionRestoration: 'enabled', // Add options right here
			relativeLinkResolution: 'legacy'
		}),
		TranslateModule.forRoot(),

		//NgBootstrap
		NgbModule,
		ToastrModule.forRoot(),

		// Core modules
		CoreModule.forRoot(coreConfig),
		CoreCommonModule,
		CoreSidebarModule,
		CoreThemeCustomizerModule,

		// App modules
		LayoutModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
		  enabled: environment.production,
		  // Register the ServiceWorker as soon as the application is stable
		  // or after 30 seconds (whichever comes first).
		  registrationStrategy: 'registerWhenStable:30000'
    }),
	],
	providers: [
		AUTH_HTTP_INTERCEPTOR_PROVIDER
		// { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
